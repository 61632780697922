
import { ContentItem } from "@/types";
import { Component, Vue } from "vue-property-decorator";
import { ROOT_ACTIONS } from "@/store/actions";
import ContentViewer from '@/components/ContentViewer.vue'
import ConfirmationDialog from "@/components/dialogs/ConfirmationDialog.vue";
import { collection, deleteDoc, doc, getDoc, getDocs, query, updateDoc, where } from "firebase/firestore";
import { firestore, storage } from "@/firebase";
import { deleteObject, ref } from "firebase/storage";

@Component({
  components: {
    ContentViewer,
    ConfirmationDialog
  }
})
export default class Index extends Vue {
  item: null | ContentItem = null;

  dialogs = {
    delete: false,
    archive: false,
    edit: false,
  }

  loading = {
    init: true,
    delete: false,
    archive: false,
  }

  titleCopy = '';
  languageCopy = '';


  languages = [
    {
      text: 'Nederlands',
      value: 'nl-be'
    },
    {
      text: 'Frans',
      value: 'fr'
    }
  ]

  fab = false;

  isEditingTitle = false;

  async deleteItem() {
    try {
      this.loading.delete = true;

      const assignments = await getDocs(query(collection(firestore, 'content-assignments'), where('contentId', '==', this.$route.params.id)))

      if (assignments.size !== 0) {
        this.$store.dispatch(ROOT_ACTIONS.TOGGLE_NOTIFICATION, {
          color: 'error',
          text: 'Item nog in gebruik',
        })
        return;
      }

      await deleteDoc(doc(firestore, 'content-items', this.$route.params.id))
      const fileRef = ref(storage, `content-items/${this.$route.params.id}`);
      await deleteObject(fileRef);

      this.$store.dispatch(ROOT_ACTIONS.TOGGLE_NOTIFICATION, {
        color: 'success',
        text: 'Item verwijderd',
      })
      this.$router.push('/admin/library')
    } catch (error) {
      this.$store.dispatch(ROOT_ACTIONS.TOGGLE_NOTIFICATION, {
        color: 'error',
        text: error
      })
    } finally {
      this.loading.delete = false;
    }
  }

  async archiveItem() {
    try {
      this.loading.delete = true;
      await updateDoc(doc(firestore, 'content-items', this.$route.params.id), {
        archived: true
      })

      this.$store.dispatch(ROOT_ACTIONS.TOGGLE_NOTIFICATION, {
        color: 'success',
        text: 'Item gearchiveerd',
      })
      this.$router.push('/admin/library')
    } catch (error) {
      this.$store.dispatch(ROOT_ACTIONS.TOGGLE_NOTIFICATION, {
        color: 'error',
        text: error
      })
    } finally {
      this.loading.delete = false;
    }
  }

  async submit() {
    try {
      if(this.titleCopy.length === 0) {
        this.$store.dispatch(ROOT_ACTIONS.TOGGLE_NOTIFICATION, {
          color: 'error',
          text: 'Titel is verplicht',
        })
        return;
      }
      await updateDoc(doc(firestore, 'content-items', this.$route.params.id), {
        title: this.titleCopy,
        language: this.languageCopy
      })
      this.item!.title = this.titleCopy;
      this.item!.language = this.languageCopy;
      this.dialogs.edit = false;
      this.$store.dispatch(ROOT_ACTIONS.TOGGLE_NOTIFICATION, {
        color: 'success',
        text: 'Item bewerkt',
      })
    } catch (error) {
      this.$store.dispatch(ROOT_ACTIONS.TOGGLE_NOTIFICATION, {
        color: 'error',
        text: error
      })
    }
  }

  async mounted() {
    const item = await getDoc(doc(firestore, 'content-items', this.$route.params.id))

    this.item = {
      id: item.id,
      ...item.data()
    } as ContentItem

    this.titleCopy = this.item.title;
    this.languageCopy = this.item.language || '';

    this.$store.dispatch(ROOT_ACTIONS.SET_BREADCRUMBS, [{ text: 'Bibliotheek', target: '/admin/library' }, {
      text: this.item.title,
      target: `#`
    }])
  }
}
